<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    dark
    app
    v-bind="$attrs"
  >
    <v-list-item>
      <v-list-item-content class="pa-0">
        <v-list-item-title class="display-2 text-center font-weight-black">
          <v-img
            :src="
              require('@/assets/images/logos/menu.png')
            "
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list class="pt-0">
      <template v-for="(item, i) in menu">
        <template v-if="item.itens">
          <v-list-group
            :key="`group-${i}`"
            no-action
            :prepend-icon="item.icon"
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" />
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(submenu, si) in item.itens"
              :key="`submenu-${si}`"
              :to="submenu.link"
              active-class="info white--text"
              link
            >
              <v-list-item-icon>
                <v-icon v-text="submenu.icon" />
              </v-list-item-icon>

              <v-list-item-title v-text="submenu.text" />
            </v-list-item>
          </v-list-group>
        </template>

        <template v-else>
          <v-list-item
            v-if="item.show"
            :key="`group-${i}`"
            :to="item.link || ''"
            active-class="info white--text"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import menu from '@/utils/menu'

  export default {

    data: () => ({
      menu: menu(),
    }),

    computed: {
      drawer: {
        get () {
          return this.$store.state.layout.drawer
        },
        set (val) {
          this.$store.commit('layout/SET_DRAWER', val)
        },
      },
    },

  }
</script>
