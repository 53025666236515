import store from '@/store'

const hasPermission = store.getters['user/hasPermission']

export default () => {
  return [
    {
      text: 'Dashboard',
      icon: 'mdi-view-dashboard',
      link: '/',
      show: true,
    },
    {
      text: 'Lançamentos',
      icon: 'mdi-currency-usd',
      link: '/lancamentos',
      show: hasPermission('apportionments')
    },
    {
      text: 'Prefeituras',
      icon: 'mdi-office-building-outline',
      link: '/prefeituras',
      show: hasPermission('cities')
    },
    {
      text: 'Associações',
      icon: 'mdi-handshake',
      link: '/associacoes',
      show: hasPermission('associations')
    },
    {
      text: 'Pesquisas',
      icon: 'mdi-comment-question',
      link: '/pesquisas',
      show: hasPermission('surveys')
    },
    {
      text: 'Tabela de Contribuição',
      icon: 'mdi-tune',
      link: '/tabela-de-contribuicao',
      show: hasPermission('contributions-ranges')
    },
    {
      text: 'Recibos',
      icon: 'mdi-receipt',
      link: '/recibos',
      show: hasPermission('receipts')
    },
    {
      text: 'Comunicados',
      icon: 'mdi-email-multiple',
      link: '/comunicados',
      show: hasPermission('announcements')
    },
    {
      text: 'Eventos',
      icon: 'mdi-calendar-star',
      link: '/eventos',
      show: hasPermission('events')
    },
    {
      text: 'Usuários',
      icon: 'mdi-account',
      link: '/usuarios',
      show: hasPermission('users')
    },
  ]
}
